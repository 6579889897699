import PropTypes from 'prop-types';
import { Backdrop, CircularProgress, Typography } from '@mui/material';

const LoaderWidget = (props) => {
  return(
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
    open={props.loader}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
  )
};

export default LoaderWidget;

LoaderWidget.propTypes = {
  message: PropTypes.string,
  loader: PropTypes.bool,
}