import { useState, useEffect } from "react";
import { Box, Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { upperCase } from "lodash";

const InventoryEditDialog = ({ openDialogEdit, openModalEdit, closeModalEdit, product, editInventory }) => {
  const [currentProduct, setCurrentProduct] = useState({
    quantity: 0,
    current: 0,
    stock: 0
  });

  const { quantity, current, stock } = currentProduct;

  const handleChange = (e) => {
    setCurrentProduct({
      ...currentProduct,
      [e.target.name] : e.target.value
    })
  }

  const saveInventory = e => {
    e.preventDefault();
    editInventory(currentProduct);
  }

  useEffect(() => {
    setCurrentProduct(product);
  }, [product])

  return (
    <Dialog open={openDialogEdit} maxWidth="sm" fullWidth={true} scroll="paper">
      <form onSubmit={saveInventory}>
        <DialogTitle>
          {`${product.category_name} ${upperCase(product.product_name)} ${upperCase(product.color_name)} ${product.size_name}`}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} mt={0.5}>
              <Grid item lg={4} md={4} xs={4}>
                <TextField
                  type="number"
                  name="quantity"
                  value={quantity}
                  onChange={handleChange}
                  placeholder="Cantidad"
                />
              </Grid>
              <Grid item lg={4} md={4} xs={4}>
              <TextField
                  type="number"
                  name="current"
                  value={current}
                  onChange={handleChange}
                  placeholder="Actual"
                />
              </Grid>
              <Grid item lg={4} md={4} xs={4}>
              <TextField
                  type="number"
                  name="stock"
                  value={stock}
                  onChange={handleChange}
                  placeholder="Stock"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalEdit} variant="outlined">
            Cancelar
          </Button>
          <Button type="submit" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InventoryEditDialog;
