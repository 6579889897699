import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import userReducer from './features/user/userSlice';
import appReducer from './features/appSlice';
import snackReducer from './features/snackbar/snackbarSlice';

const reducers = combineReducers({
  user: userReducer,
  app: appReducer,
  snackbar: snackReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
