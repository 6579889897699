import { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Stack,
  TableContainer,
  Paper
} from "@mui/material";
import { upperCase } from "lodash";

export const StoreListResults = ({
  products,
  changePage,
  changeLimit,
  count,
  openModalEdit,
  openModalInventory,
  ...rest
}) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  return (
    <>
      <Card {...rest}>
        <TableContainer component={Paper}>
          <Table sx={{ maxHeight: 440 }}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((product) => (
                <TableRow
                  hover
                  key={product.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell padding="checkbox">{product.id}</TableCell>
                  
                  <TableCell>{upperCase(product.name)}</TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => openModalEdit(product)}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => openModalInventory(product)}
                      >
                        Inventario
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Card>
    </>
  );
};

StoreListResults.propTypes = {
  products: PropTypes.array.isRequired,
};
