import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PaymentService from '../../../services/payment.service';
import SelectField from '../select.widget';

export const PaymentSelect = ({...props}) => {

  const [payments, setPayments] = useState([]);
  const paymentService = new PaymentService();
  const { user } = useSelector(state => state.user);

  const getPayments = async() => {
    try {
      paymentService.setAccessToken(user.accessToken);
      const result =  await paymentService.getPayments();
      const pays = result.data;
      setPayments(pays.map(p => ({value: Number(p.id), key: p.name})));
    } catch (error) {
      
    }
  }

  useEffect(() => {
    getPayments();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return(
     <SelectField
      data={payments}
      {...props}
    />
  );
}