import AppService from "./app.service";

class ProductService extends AppService {
  constructor() {
    super();
    this.path = this.getPath("products");
  }

  getProducts(search) {
    return this.http.get(`${this.path}?search_by=${search}`);
  }

  getProductsPerPage(pageNumber) {
    return this.http.get("/products?page=" + pageNumber);
  }

  getProductsPerSearch(value) {
    return this.http.get("/products/search?search=" + value);
  }

  getProductSelect() {
    return this.http.get(`inventory/select`);
  }

  getSelect(search, page, store = 1) {
    return this.http.get(
      `inventory/get-select?search=${search}&store=${store}&page=${page}`
    );
  }

  getCategories() {
    return this.http.get("/category");
  }

  newProduct(data) {
    return this.http.post("/product/new", data);
  }

  updateProduct(id, data) {
    return this.http.put("/product/update/" + id, data);
  }
 
  list(pageSize, page) {
    return this.http.get(
      `${this.path}?render=paginate&size=${pageSize}&page=${page}`
    );
  }
  
  stock(id) {
    return this.http.get('/products/' + id + '/stock');
  }
}

export default ProductService;
