import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const sizes = [
  { value: 1, key: "S" },
  { value: 2, key: "M" },
  { value: 3, key: "L" },
  { value: 4, key: "L+" },
  { value: 5, key: "XS" },
  { value: 6, key: "27" },
  { value: 7, key: "29" },
  { value: 8, key: "-" },
];

let numbers = [];
for (let i = 1; i < 11; i++) {
  numbers.push({ value: i, key: i });
}

const SizeItem = ({
  index,
  removeHandler,
  size_id,
  quantity,
  handleChange,
}) => {
  return (
    <Grid container columnSpacing={3} rowSpacing={1} mt={0.5}>
      <Grid item lg={5} md={5} xs={5}>
        <FormControl fullWidth>
          <InputLabel id="size_id_label">Talla</InputLabel>
          <Select
            labelId="size_id_label"
            id="size_id"
            name="size_id"
            value={size_id}
            label="Talla"
            onChange={handleChange(index)}
          >
            {sizes.map((size, i) => (
              <MenuItem key={i} value={size.value}>
                {size.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={5} md={5} xs={5}>
        <FormControl fullWidth>
          <InputLabel id="quantity_label">Cantidad</InputLabel>
          <Select
            labelId="quantity_label"
            id="quantity"
            name="quantity"
            value={quantity}
            label="Cantidad"
            onChange={handleChange(index)}
          >
            {numbers.map((q, i) => (
              <MenuItem key={i} value={q.value}>
                {q.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={2} md={2} xs={2}>
        <Button
          variant="contained"
          color="error"
          onClick={removeHandler(index)}
        >
          X
        </Button>
      </Grid>
    </Grid>
  );
};

export default SizeItem;
