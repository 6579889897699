import { Bar } from 'react-chartjs-2';
import { Box, Button, Card, CardContent, CardHeader, Divider, useTheme, FormControl, InputLabel, Select, MenuItem  } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const Sales = (props) => {
  const theme = useTheme();

  const data = {
    datasets: [
      {
        backgroundColor: '#3F51B5',
        barPercentage: 0.5,
        barThickness: 12,
        borderRadius: 4,
        categoryPercentage: 0.5,
        data: [18, 5, 19, 27, 29, 19, 20],
        label: 'This year',
        maxBarThickness: 10
      },
      // {
      //   backgroundColor: '#EEEEEE',
      //   barPercentage: 0.5,
      //   barThickness: 12,
      //   borderRadius: 4,
      //   categoryPercentage: 0.5,
      //   data: [11, 20, 12, 29, 30, 25, 13],
      //   label: 'Last year',
      //   maxBarThickness: 10
      // }
    ],
    labels: ['1 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug', '7 aug']
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    xAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    ],
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  return (
    <Card {...props}>
      <CardHeader
        action={(
          <Box className="Daniel">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'no-wrap',
                m: -1,
                width: '472px'
              }}
              className='HOLALAA'
            >
              <FormControl fullWidth>
                <InputLabel id="status">Tienda</InputLabel>
                <Select
                  fullWidth
                  value={props.valSelect}
                  label="Tienda"
                  onChange={props.onSelect}
                  name="filter"
                  sx={{width: '150px'}}
                >
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value={1}>Online</MenuItem>
                  <MenuItem value={2}>Jesus Maria</MenuItem>
                  <MenuItem value={3}>Surco</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="status">Año</InputLabel>
                <Select
                  fullWidth
                  value={props.valSelect}
                  label="Año"
                  onChange={props.onSelect}
                  name="filter"
                  sx={{width: '150px'}}
                >
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value={2}>Entregado</MenuItem>
                  <MenuItem value={1}>Pendiente</MenuItem>
                  <MenuItem value={3}>Anulado</MenuItem>
                  <MenuItem value={4}>Falta pagar</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="status">Mes</InputLabel>
                <Select
                  fullWidth
                  value={props.valSelect}
                  label="Mes"
                  onChange={props.onSelect}
                  name="filter"
                  sx={{width: '150px'}}
                >
                  <MenuItem value={0}>Todos</MenuItem>
                  <MenuItem value={2}>Entregado</MenuItem>
                  <MenuItem value={1}>Pendiente</MenuItem>
                  <MenuItem value={3}>Anulado</MenuItem>
                  <MenuItem value={4}>Falta pagar</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        title="Latest Sales"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon fontSize="small" />}
          size="small"
        >
          Overview
        </Button>
      </Box>
    </Card>
  );
};
