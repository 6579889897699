import { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { snackbarClear } from "../../../app/features/snackbar/snackbarSlice";


const SnackbarUI = () => {

  const {snackbarOpen, snackbarMessage, snackbarType} =  useSelector(state => state.snackbar);
  const dispatch = useDispatch();

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(snackbarClear());
  };

  return(
    <Snackbar 
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right'} }>
      <Alert onClose={handleClose} severity={snackbarType} sx={{ width: "100%" }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  )
}


export default SnackbarUI;