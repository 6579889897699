import { serialize } from 'object-to-formdata';

import AppService from './app.service';

class PaymentService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('payment');
  }

  getPayments () {
    return this.http.get(`${this.path}`);
  }
}

export default PaymentService;
