import React, {useState} from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { DashboardNavbar } from '../../components/dashboard-navbar';
import { DashboardSidebar } from '../../components/dashboard-sidebar';
import Navbar from './Navbar';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 280
    }
  }));


const useAuth = () => {
  const { user } = useSelector((state) => state.user);
  

  if(!user) return false;

  const accessToken = user.accessToken;
  return accessToken ? true : false;
};

const AdminLayout = () => {
  const auth = useAuth();
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  return auth ? (
    <>
      <DashboardLayoutRoot>
        <Box
            sx={{
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Outlet />
          </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
      {/* <Navbar /> */}
    </>
  ) : (
    <Navigate to='/login' />
  );
};

export default AdminLayout;