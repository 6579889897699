import { Formik, Form } from 'formik';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from 'yup';

import ColorForm from "./forms/color-form";

const validationSchema = yup.object({
  name: yup
    .string('Ingrese el nombre')
    .required('El nombre es obligatorio')
});

const ColorDialog = ({open, color, closeModal, saveColor}) => {

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} scroll="paper">
      <Formik
        initialValues={color}
        onSubmit={
          (values) => {
            saveColor(values);
          }
        }
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => (
          <Form >
            <DialogTitle>
              Nuevo Color
            </DialogTitle>
            <DialogContent>
              <ColorForm {...props} />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal} variant="outlined">
                Cancelar
              </Button>
              <Button type="submit" variant="contained">
                Guardar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ColorDialog;
