import { Formik, Form } from 'formik';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from 'yup';

import ProductForm from "./forms/product-form";

const validationSchema = yup.object({
  name: yup
    .string('Ingrese el nombre')
    .required('El nombre es obligatorio'),
  category_id: yup
    .string('Elija una categoria')
    .required('La categoria es obligatorio'),
  price: yup
  .string('Debe ingresar un número')
  .matches(/^[0-9]+$/, "Ingresa solo Numeros")
  .required('El precio es obligatorio'),
});

const StoreDialog = ({open, product, categories, closeModal, saveProduct}) => {

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} scroll="paper">
      <Formik
        initialValues={product}
        onSubmit={
          (values) => {
            saveProduct(values);
          }
        }
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => (
          <Form >
            <DialogTitle>
              Nuevo Producto
            </DialogTitle>
            <DialogContent>
              <ProductForm {...props} categories={categories}  />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal} variant="outlined">
                Cancelar
              </Button>
              <Button type="submit" variant="contained">
                Guardar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default StoreDialog;
