import {Grid} from "@mui/material";
import InputField from "../../../core/widgets/input.widget";
import SelectField from "../../../core/widgets/select.widget";
import { PaymentSelect } from "../../../core/widgets/custom/payment.select";

const isSunat = [
  {
    value: 'true',
    key: 'Si'
  },
  {
    value: 'false',
    key: 'No'
  }
];

const statusList = [
  {
    value: 1,
    key: 'Pendiente'
  },
  {
    value: 2,
    key: 'Entregado'
  },
  {
    value: 3,
    key: 'Anulado'
  },
  {
    value: 4,
    key: 'Falta Pagar'
  }
];

const storeList = [
  {
    value: 1,
    key: 'Online'
  },
  {
    value: 2,
    key: 'Jesus Maria'
  },
  {
    value: 3,
    key: 'Surco'
  }
]

const PaymentSaleForm = (props) => {
  
  return(
    <>
      <Grid container
        spacing={2}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <PaymentSelect
            fullWidth
            label="Forma de pago"
            name='payment_id'
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <SelectField
            label="Enviar a Sunat"
            fullWidth
            name='is_sunat'
            data={isSunat}
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <SelectField
            label="Estado"
            fullWidth
            name='status'
            data={statusList}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <SelectField
            label="Vendedor"
            fullWidth
            name='user_id'
            data={props.users}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={12}
          xs={12}
        >
          <SelectField
            label="Tienda"
            fullWidth
            name='store_id'
            data={storeList}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputField
            fullWidth
            label="Total"
            name="price"
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputField
            fullWidth
            label="Efectivo"
            name="cash"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </>
  )

}

export default PaymentSaleForm;