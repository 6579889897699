import { serialize } from 'object-to-formdata';

import AppService from './app.service';

class AuthService extends AppService {
  login(credentials) {
    const formData = serialize(credentials);
    return this.http.post('/sign-in', formData);
  }
  recoverPassword(data) {
    const formData = serialize(data);
    return this.http.post('/auth/recover-password', formData);
  }
  resetPassword(data) {
    const formData = serialize(data);
    return this.http.post('auth/reset-password', formData);
  }

}

export default AuthService;
