import { useState, useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import { CustomerListToolbar } from "../components/customer/customer-list-toolbar";
import { CustomerListResults } from "../components/customer/customer-list-results";
import { CustomerDialog } from "../components/customer/customer-dialog";
import CustomerService from "../services/customer.service";

const initialValues = {
  first_name: "",
  last_name: "",
  full_name: "",
  address: "",
  phone: "",
  email: "",
  dni: "",
  birthday: "",
  reference: ""
};

const Customers = () => {

  const customerService = new CustomerService();
  const { user } = useSelector((state) => state.user);
  const [customers, setCustomers] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [customer, setCustomer] = useState(initialValues);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState('');
  
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getCustomers = async (p, l, s, f) => {
    try {
      customerService.setAccessToken(user.accessToken);

      const response = await customerService.getCustomers(p, l, s, f);
      setData(response.data);
      setCustomers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page) => {
    setPage(page);
  };
  const changeLimit = (limit) => {
    setLimit(limit);
  };

  const handleInput = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handleModal = () => {
    setOpen(true);
  };

  const handleModalEdit = (p) => {
    setCustomer(p);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setCustomer(initialValues);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const showMessage = (txt, action) => {
    setMessage(txt);
    setAlert(action)
  }

  const searchDni = async(val) => {
    try {
      customerService.setAccessToken(user.accessToken);
      const response = await customerService.searchDni(val);
      setCustomer({
        ...customer,
        dni: response.data.dni,
        full_name: response.data.full_name,
        first_name: response.data.first_name,
        last_name: response.data.last_name
      })
    } catch (error) {
      
    }
  }

  const saveCustomer = async (data) => {
    try {
      customerService.setAccessToken(user.accessToken);
      if (data.id) {
        await customerService.updateCustomer(data.id, data);
      } else {
        await customerService.newCustomer(data);
      }
      getCustomers(page, limit, search);
      handleModalClose();
      setOpenAlert(true);
      showMessage("Cliente guardado!", "success");
    } catch (error) {
      console.log(error);
      setOpenAlert(true);
      showMessage("Se produjo un error!", "error");
    }
  };

  useEffect(() => {
    getCustomers(page, limit, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, search]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <CustomerListToolbar onInput={handleInput} openModal={handleModal} />
          <CustomerDialog
            open={open}
            closeModal={handleModalClose}
            customer={customer}
            saveCustomer={saveCustomer}
            searchDni={searchDni}
          />
          <Box sx={{ mt: 3 }}>
            <CustomerListResults
              customers={customers}
              changePage={changePage}
              changeLimit={changeLimit}
              count={data.records_total || 0}
              open={open}
              closeModal={handleModalClose}
              openModalEdit={handleModalEdit}
              customer={customer}
            />
          </Box>
        </Container>
      </Box>
      <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alert} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Customers;
