import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SnackbarUI from '../widgets/loader/SnackbarUI';

import { AdminRoute } from './admin.route';
import { WebRoute } from './web.route';

const AppRoute = () => (
  <>
    <SnackbarUI />
    <BrowserRouter>
      <Routes>
        <Route path='*' element={<WebRoute />} />
        <Route exact path='/admin/*' element={<AdminRoute />} />
      </Routes>
    </BrowserRouter>
  </>
);

export default AppRoute;