import {
  Box,
  Grid,
  TextField,
} from "@mui/material";

const ColorForm = (props) => {
  const { values, errors, handleChange, handleBlur, touched } =
    props;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mt={0.5}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="name"
            value={values.name}
            placeholder="Nombre"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="code"
            value={values.code}
            placeholder="Código"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.code && Boolean(errors.code)}
            helperText={touched.code && errors.code}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ColorForm;
