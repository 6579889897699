import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import 'dayjs/locale/es-mx';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Search as SearchIcon } from "../../icons/search";

export const SalesListToolbar = ({query, onQuery, onInput}) => {

  const onDate = (val) => {
    if (val[0] && val[1]){
      onInput(val);
    }
  }
   
  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: -1,
        }}
      >
        <Typography sx={{ m: 1 }} variant="h4">
          Ventas
        </Typography>
        <Box sx={{ m: 1 }}>
          <Link to="new">
            <Button color="primary" variant="contained">
              Nueva Venta
            </Button>
          </Link>
        </Box>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={4}>
                <TextField
                  type="search"
                  name="search"
                  fullWidth
                  onChange={onQuery}
                  value={query.search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon color="action" fontSize="small">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                  placeholder="Buscar cliente por nombre o DNI"
                  variant="outlined"
                  sx={{ marginTop: "8px" }}
                />
              </Grid>
              <Grid 
                item 
                xs={12}
                sm={6}
                lg={4}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DateRangePicker']}>
                    <DateRangePicker
                      value={query.range_date}
                      onChange={newValue => onDate(newValue)}
                      format="DD/MM/YYYY"
                      localeText={{ start: 'Desde', end: 'Hasta' }}
                      slotProps={{
                        textField: {
                          error: false,
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid 
                item 
                xs={12}
                sm={6}
                lg={2}
              >
                <FormControl fullWidth sx={{ marginTop: "8px" }}>
                  <InputLabel id="store_id">Tienda</InputLabel>
                  <Select
                    fullWidth
                    label="Tienda"
                    onChange={onQuery}
                    value={query.store_id}
                    name="store_id"
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={1}>Online</MenuItem>
                    <MenuItem value={2}>Jesus Maria</MenuItem>
                    <MenuItem value={3}>Surco</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid 
                item 
                xs={12}
                sm={6}
                lg={2}
              >
                <FormControl fullWidth sx={{ marginTop: "8px" }}>
                  <InputLabel id="status">Estado</InputLabel>
                  <Select
                    fullWidth
                    label="Estado"
                    name="status"
                    onChange={onQuery}
                    value={query.status}
                  >
                    <MenuItem value={0}>Todos</MenuItem>
                    <MenuItem value={2}>Entregado</MenuItem>
                    <MenuItem value={1}>Pendiente</MenuItem>
                    <MenuItem value={3}>Anulado</MenuItem>
                    <MenuItem value={4}>Falta pagar</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
