import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import Router  from 'next/router';
import { Box, Button, Grid, Container, Card, CardHeader, CardContent } from "@mui/material";
import { Formik, Form } from 'formik';
import ItemsSale from "../../components/sale/items.sale";
import InputField from '../../core/widgets/input.widget';
import { useSelector, useDispatch } from 'react-redux';
import ProductService from '../../services/product.service';
import UserService from '../../services/user.service';
import SaleService from '../../services/sale.service';
import SaleForm from '../..//components/sale/forms/sale.form';
import DetailSaleForm from '../..//components/sale/forms/detail.form';
import PaymentSaleForm from '../..//components/sale/forms/payment.form';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import CustomerService from "../..//services/customer.service";
import LoaderWidget from '../..//core/widgets/loader/loader.widget';
import { loaderStatus } from '../../app/features/appSlice';
import { cloneDeep as _cloneDeep, sumBy as _sumBy } from 'lodash';
import dayjs from 'dayjs';

const initialValues = {
  full_name: '',
  last_name:'',
  first_name: '',
  dni: '',
  birthday: null,
  email: '',
  phone: '',
  address: '',
  document: 3,
  ruc: '',
  bussines_name: '',
  bussines_address: '',
  sale_at: new Date(),
  send_at: new Date(),
  shipping_type: 4,
  payment_id: 3,
  nro_tracking: '',
  shipping: 0,
  description: '',
  cash: 0,
  is_sunat: true,
  user_id: 1,
  price: 0,
  status: 2,
  store_id: 1,
  client_id: 1
};

const NewSalePage = (props) => {
  const navigate = useNavigate();
  const productService = new ProductService();
  const customerService =  new CustomerService();
  const saleService = new SaleService();
  const userService = new UserService();
  const [loader, setLoader] = useState(false);
  const { user, store } = useSelector(state => state.user);
  const dispatch = new useDispatch();
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [sale, setSale] = useState(initialValues);
  const [items, setItems] = useState([{
    id: '',
    quantity: '',
    price: '',
    inventory_id: '',
    product: null
  }]);

  const getProducts = async () => {
    dispatch(loaderStatus());
    try {
      productService.setAccessToken(user.accessToken);
      const response = await productService.getProductSelect();
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    // alert('a');
    try {
      userService.setAccessToken(user.accessToken);
      const response = await userService.list();
      console.log(response.data);
      setUsers(response.data.map(user => ({value: user.id, key: `${user.first_name} ${user.last_name}`})));
      
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddItem = () => {
    setItems(oldArray => [...oldArray, { id: uuidv4(), quantity: 1, price: '', inventory_id: '', product: null }]);
  }

  const handleRemoveItem = (elementIndex) => () => {
    const copy = _cloneDeep(items);
    const it = copy.filter((_, i) => elementIndex !== i);
    setItems(it);
    // setSale({...sale, price: _sumBy(it, item => Number(item.price))});
  }

  const selectProduct = (elementIndex, val) => {
    if (val) {
      let newArray = [...items];
      newArray[elementIndex] = {'quantity': 1, 'product': val, 'price': parseInt(val.price), 'total': val.price, 'inventory_id': val.id};
      setItems(newArray);
      // setSale({...sale, price: _sumBy(newArray, item => Number(item.price))});
    }
  }

  const handleItemChange = (elementIndex) => (event) => {
    if(items){
      let newItems = items.map((item, i) => {
        if (elementIndex !== i) return item;
        if (event.target) {
          return {...item, [event.target.name]: event.target.value}
        } else {
          return {...item, 'quantity': 1, 'product': event, 'price': parseInt(event.price), 'total': event.price, 'inventory_id': event.value}
        }
        
      });
      // setSale({...sale, price: _sumBy(newItems, item => Number(item.price))});
      setItems(newItems);

    }
  }

  const passData = (data) => {
    findCustomer(data);
  };

  const passDataRuc = async (data) => {
    setLoader(true);
    try {
      customerService.setAccessToken(user.accessToken);
      const response =  await customerService.searchRuc(data);
      const info = response.data;
      if(info) {
        setSale({
          ...sale,
          document: 1,
          ruc: info.ruc,
          bussines_name: info.bussines_name,
          bussines_address: info.bussines_address,
        })
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  const findCustomer =  async (customerDni) => {
    try {
      setLoader(true);
      customerService.setAccessToken(user.accessToken);
      const response =  await customerService.searchDni(customerDni);
      const customer = response.data;
      if (customer) {
        setSale(prevState => ({
          ...prevState, 
          full_name: customer.full_name,
          first_name: customer.first_name,
          last_name: customer.last_name,
          address: customer.address,
          phone: customer.phone,
          email: customer.email,
          birthday: customer.birthday ?? null,
          dni: customerDni
        }));
      } 
      else {
        setSale(prevState => ({
          ...prevState,
          full_name: '',
          first_name: '',
          last_name: '',
          address:'',
          phone: '',
          email: '',
          birthday: null,
          dni: customerDni
        }));
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const createSale = async (data) => {
    setLoader(true);
    try {
      saleService.setAccessToken(user.accessToken);
      const res = await saleService.create(data);
      if (res) {
        // console.log(res);
        (data.is_sunat) ? navigate(`/admin/document/${res.data.sale_id}`) : navigate('/admin/sales');
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  const valStore = (val) => {
    switch(val) {
      case 1: return 3;
              break;
      case 2: return 1;
              break
      case 3: return 2;
              break;
    }
  }


  useEffect(() =>{
    getProducts();
    getUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <h1>Ventas</h1>
        <Container maxWidth={false}>
          <Formik
            initialValues= {sale}
            onSubmit= {
              (values) => {
                values.price = _sumBy(items, item => Number(item.price));
                values.items = JSON.stringify(items);
                // values.store_id = 3;
                // values.store_id = store;
                values.client_id = valStore(values.store_id);
               
                // values.user_id = user.id;
                values.birthday = dayjs(values.birthday).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
                values.sale_at = dayjs(values.sale_at).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
                values.send_at = dayjs(values.send_at).format('YYYY-MM-DDTHH:mm:ssZ[Z]');
                // console.log(values);
                createSale(values);
            }
            }
            validationSchema= {
              Yup.object({
                dni: Yup.string().required('El dni es obligatorio')
                  .matches(/^[0-9]+$/, "Ingresa solo Numeros")
                  .min(8, "Debes ingresar 8 digitos")
                  .max(8, "Debes ingresar 8 digitos"),
                phone: Yup.string().required('El celular es obligatorio')
                  .matches(/^[0-9]+$/, "Ingresa solo Numeros")
                  .min(9, "Debes ingresar 11 digitos")
                  .max(9, "Debes ingresar 11 digitos"),
                full_name: Yup.string().required('Los nombres y Apellidos son obligatorios'),
                address: Yup.string().required('La direccion es obligatorio'),
                ruc: Yup.string().when('document', {
                  is: 1,
                  then: Yup.string()
                  .required('El RUC es obligatorio')
                  .matches(/^[0-9]+$/, "Ingresa solo Numeros")
                  .min(11, "Debes ingresar 11 digitos")
                  .max(11, "Debes ingresar 11 digitos")
                }),
                bussines_name: Yup.string().when('document', {
                  is: 1,
                  then: Yup.string().required('La razon social es obligatorio')
                }),
                bussines_address: Yup.string().when('document', {
                  is: 1,
                  then: Yup.string().required('La dirección es obligatorio')
                })
              })
            }
            enableReinitialize
          >
            {(props) => (
              <Form>
                <Grid container
                  spacing={1}
                >
                  <Grid
                    item
                    lg={7}
                    md={7}
                    xs={12}
                  >
                    <Card>
                      <CardHeader 
                        title="Datos del Cliente"
                      />
                      <CardContent>
                        <SaleForm
                          {...props}
                          passData={passData}
                          passDataRuc={passDataRuc}
                          typeForm={2}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item
                    lg={5}
                    md={5}
                    xs={12}
                  >
                    <Card>
                      <CardHeader title="Detalle"/>
                      <CardContent>
                        <DetailSaleForm />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item
                    lg={8}
                    md={8}
                    xs={12}
                  >
                    <Card>
                      <CardHeader title="Productos"/>
                      <CardContent>
                        <ItemsSale
                          items={items}
                          products={products}
                          addHandler={handleAddItem}
                          deleteHandler={handleRemoveItem}
                          changeHandler={handleItemChange}
                          selectProduct={selectProduct}
                        />
                      </CardContent>
                    </Card>
                    <Card style={{marginTop: 10}}>
                      <CardHeader title="Notas"/>
                      <CardContent>
                        <InputField
                          name="description"
                          value={props.description}
                          label="Nota"
                          placeholder="Nota"
                          fullWidth
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item
                    lg={4}
                    md={4}
                    xs={12}
                  >
                    <Card>
                      <CardHeader title="Pagos"/>
                      <CardContent>
                        <PaymentSaleForm users={users} />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid>
                    <Button type="submit">Enviar</Button>
                  </Grid> 
                </Grid>
              </Form> 
              )}
            </Formik>
        </Container>
      </Box>
      <LoaderWidget loader={loader} />
    </>
  );
};

export default NewSalePage;