import { useState } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material"

export const AutocompleteComponent = ({data, label, handleInput, loading, selectItem, value}) => {
  const [item, setItem] = useState("");

  const select= (val) => {
    setItem(val);
  };

  return(
    <Autocomplete
      options={data || null}
      value={value || null}
      loading={loading}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
      onInputChange={(_, newValue) => {handleInput(newValue);}}
      onChange={(_, newValue) => {selectItem(newValue); select(newValue)}}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" 
                  size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />)}
    />
  )
};

