import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
// import {  cloneDeep as _cloneDeep  } from "lodash";
import { useParams, Link } from "react-router-dom";
import { Box, Button, Grid, Container, Card, CardHeader, CardContent } from "@mui/material";
import SaleService from "../../services/sale.service";
import * as Yup from 'yup';

import LoaderWidget from "../../core/widgets/loader/loader.widget";
import SaleForm from "../../components/sale/forms/sale.form";
import DetailSaleForm from "../../components/sale/forms/detail.form";
import InputField from "../../core/widgets/input.widget";
import SelectField from "../../core/widgets/select.widget";
import { PaymentSelect } from '../../core/widgets/custom/payment.select'
import UserService from "../../services/user.service";
// import dayjs from 'dayjs';
// import { useNavigate } from 'react-router-dom';

// import { CreateDocument } from "../../components/document/create";

const isSunat = [
  {
    value: 'true',
    key: 'Si'
  },
  {
    value: 'false',
    key: 'No'
  }
];

const statusList = [
  {
    value: 1,
    key: 'Pendiente'
  },
  {
    value: 2,
    key: 'Entregado'
  },
  {
    value: 3,
    key: 'Anulado'
  },
  {
    value: 4,
    key: 'Falta Pagar'
  }
];

const storeList = [
  {
    value: 1,
    key: 'General'
  },
  {
    value: 2,
    key: 'Jesus Maria'
  },
  {
    value: 3,
    key: 'Surco'
  }
]

const SaleEdit = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state) => state.user);
  const saleService = new SaleService();
  const userService = new UserService();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const [users, setUsers] = useState([]);
  // console.log(id);
  if (id === 'undefined') {
    window.location = '/admin/sales'
  }

  const getSale = async (saleId) => {
    setLoader(true)
    try {
      saleService.setAccessToken(user.accessToken);
      const response = await saleService.readSale(saleId);
        setData(response.data);
        setData({
          ...data,
          full_name: response.data.customer.full_name,
          dni: response.data.customer.dni,
          phone: response.data.customer.phone,
          email: response.data.customer.email,
          address: response.data.customer.address,
          birthday: response.data.customer.birthday,
          sale_at: response.data.sale_at,
          send_at: response.data.send_at,
          shipping_type: response.data.shipping_type,
          payment_id: response.data.payment_id,
          nro_tracking: response.data.nro_tracking,
          shipping: response.data.shipping,
          description: response.data.description,
          cash: response.data.cash,
          user_id: response.data.user_id,
          price: response.data.price,
          status: response.data.status,
          store_id: response.data.store_id,
        })
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const getUsers = async () => {
    // alert('a');
    try {
      userService.setAccessToken(user.accessToken);
      const response = await userService.list();
      setUsers(response.data.map(user => ({value: user.id, key: `${user.first_name} ${user.last_name}`})));
      
    } catch (error) {
      console.log(error);
    }
  };

  const updateSale = async (data, id) => {
    setLoader(true);
    try {
      saleService.setAccessToken(user.accessToken);
      const res = await saleService.update(data, id);
      if (res) {
        navigate('/admin/sales');
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  useEffect(() => {
    getSale(id);
    getUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return(
    <Container maxWidth={false}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
          // m: -1
        }}
      >
        <Box sx={{ m: 1 }}>
          <Link
            to="/admin/sales/new"
          >
            <Button
              color="primary"
              variant="contained"
            >
              Nueva Venta
            </Button>
          </Link>
        </Box>
        <Box sx={{ m: 1 }}>
          <Link
            to="/admin/sales"
          >
            <Button
              color="primary"
              variant="contained"
            >
              Lista de ventas
            </Button>
          </Link>
        </Box>
        <Box>
          {/* <ReactToPrint
            trigger={() => <Button variant="contained" >Imprimir</Button>}
            content={() => componentRef.current}
          /> */}
        </Box>
      </Box>
      {/* <Container> */}
      <Formik
            initialValues= {data}
            onSubmit= {
              (values) => {
                updateSale(values, id);
            }
            }
            validationSchema= {
              Yup.object({
                dni: Yup.string().required('El dni es obligatorio')
                  .matches(/^[0-9]+$/, "Ingresa solo Numeros")
                  .min(8, "Debes ingresar 8 digitos")
                  .max(8, "Debes ingresar 8 digitos"),
                phone: Yup.string().required('El celular es obligatorio')
                  .matches(/^[0-9]+$/, "Ingresa solo Numeros")
                  .min(9, "Debes ingresar 11 digitos")
                  .max(9, "Debes ingresar 11 digitos"),
                full_name: Yup.string().required('Los nombres y Apellidos son obligatorios'),
                address: Yup.string().required('La direccion es obligatorio'),
                ruc: Yup.string().when('document', {
                  is: 1,
                  then: Yup.string()
                  .required('El RUC es obligatorio')
                  .matches(/^[0-9]+$/, "Ingresa solo Numeros")
                  .min(11, "Debes ingresar 11 digitos")
                  .max(11, "Debes ingresar 11 digitos")
                }),
                bussines_name: Yup.string().when('document', {
                  is: 1,
                  then: Yup.string().required('La razon social es obligatorio')
                }),
                bussines_address: Yup.string().when('document', {
                  is: 1,
                  then: Yup.string().required('La dirección es obligatorio')
                })
              })
            }
            enableReinitialize
          >
            {(props) => (
              <Form>
                <Grid container
                  spacing={0}
                >
                  <Grid
                    item
                    lg={7}
                    md={7}
                    xs={12}
                  >
                    <Card>
                      <CardHeader 
                        title="Datos del Cliente"
                      />
                      <CardContent>
                        <SaleForm
                          {...props}
                          typeForm={1}
                          // passData={passData}
                          // passDataRuc={passDataRuc}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item
                    lg={5}
                    md={5}
                    xs={12}
                  >
                    <Card>
                      <CardHeader title="Detalle"/>
                      <CardContent>
                        <DetailSaleForm />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item
                    lg={8}
                    md={8}
                    xs={12}
                  >
                    <Card>
                      <CardHeader title="Productos"/>
                      <CardContent>
                        {/* <ItemsSale
                          items={items}
                          products={products}
                          addHandler={handleAddItem}
                          deleteHandler={handleRemoveItem}
                          changeHandler={handleItemChange}
                          selectProduct={selectProduct}
                        /> */}
                      </CardContent>
                    </Card>
                    <Card style={{marginTop: 10}}>
                      <CardHeader title="Notas"/>
                      <CardContent>
                        <InputField
                          name="description"
                          value={data.description}
                          label="Nota"
                          placeholder="Nota"
                          fullWidth
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item
                    lg={4}
                    md={4}
                    xs={12}
                  >
                    <Card>
                      <CardHeader title="Pagos"/>
                      <CardContent>
                        <Grid container
                          spacing={2}
                        >
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <PaymentSelect
                              fullWidth
                              label="Forma de pago"
                              name='payment_id'
                              variant="outlined"
                            />
                          </Grid>
                          {/* <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <SelectField
                              label="Enviar a Sunat"
                              fullWidth
                              name='is_sunat'
                              data={isSunat}
                            />
                          </Grid> */}
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <SelectField
                              label="Estado"
                              fullWidth
                              name='status'
                              data={statusList}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <SelectField
                              label="Vendedor"
                              fullWidth
                              name='user_id'
                              data={users}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={12}
                            xs={12}
                          >
                            <SelectField
                              label="Tienda"
                              fullWidth
                              name='store_id'
                              data={storeList}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <InputField
                              fullWidth
                              label="Total"
                              name="price"
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <InputField
                              fullWidth
                              label="Efectivo"
                              name="cash"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid>
                    <Button type="submit">Enviar</Button>
                  </Grid> 
                </Grid>
              </Form> 
              )}
            </Formik>
      {/* </Container> */}
      <LoaderWidget loader={loader} />
    </Container>
  )
};
export default SaleEdit;
