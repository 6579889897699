import { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import ItemSale from "./item.sale";
import AddIcon from '@mui/icons-material/Add';
import { sumBy as _sumBy } from 'lodash';

const ItemsSale = (props) => {
  const { items, products, addHandler, ...functions } = props;

  const select = (index, val) => {
    props.changeHandler(index, val);
  }

  const total = _sumBy(items, item => Number(item.price))

  return (
    <>
    <Box>
      { items.map((item, i) => {
        return(
          <ItemSale 
            key={i}
            index={i}
            quantity={item.quantity}
            price={item.price}
            inventory_id={item.inventory_id}
            product={item.product}
            products={products}
            selectProduct={select}
            {...functions}
          />
        )
      })}
    </Box>
    <Box>
      <Grid container>
        <Grid
          item
          xs={6}
        >
          <Button
            color='success'
            onClick={addHandler}
            startIcon={<AddIcon />}
          >
            Nuevo Item
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
        >
          {`Total: S/ ${total}`}
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default ItemsSale;