import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete, Grid, TextField, Button, Box } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDebounce } from "../../core/hooks/UseDebounce";

import ProductService from "../../services/product.service";
import { AutocompleteWidget } from "../../core/widgets/autocomplete.widget";

const valueDefault = {label: '', id: '', price: ''};

const ItemSale = (props) => {
  const { index, quantity, price, product, products} = props;
  const productService = new ProductService();
  const { user } = useSelector(state => state.user);
  const [busca, setBusca] = useState('');
  const [selectedId, setSelectedId] = useState(valueDefault);
  const { debounce } = useDebounce();

  const [opciones, setOpciones] = useState([]);

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const getProducts = async(b) => {
    await _sleep(1000);
    try {
      productService.setAccessToken(user.accessToken);
      const response = await productService.getSelect(b, 1);
      const prod = response.data.data;
      setOpciones(prod.map(p => ({id: p.value, label: p.label, price: p.price})));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
      
      getProducts(busca, 1);
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busca]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption = opciones.find(opt => opt.id === selectedId);
    if (!selectedOption) return null;

    return selectedOption;
  }, [selectedId, opciones]);

  const handleInputChange = (val) => {
    props.selectProduct(index, val)
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={8}
            md={8}
            xs={12}
          >
            <AutocompleteWidget
              selectPro={handleInputChange}
              product={product}
            />
          </Grid>
          {/* <Grid
            item
            lg={4}
            md={4}
            xs={12}>
            {/* <Autocomplete
              value={product || null}
              options={opciones}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
              onInputChange={(_, newValue) => setBusca(newValue)}
              // onChange={(_, newValue) => { }}
              onChange={(_, newValue) => {
                if (newValue) {
                  props.selectProduct(index, newValue)
                }
              }}
              renderInput={(params) => (
                <TextField 
                  {...params}
                  label="Producto" 
                  fullWidth
                />
              )}
            /> 
          </Grid> */}
          <Grid
            item
            lg={2}
            md={2}
            xs={6}
          >
            <TextField
              name='quantity'
              value={quantity}
              fullWidth
              onChange={props.changeHandler(index)}
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            xs={6}
          >
            <TextField
              name='price'
              value={price}
              fullWidth
              onChange={props.changeHandler(index)}
            />
          </Grid>
        </Grid>
        <Grid
          justifyContent='end'
          container
          pacing={3}
        >
          <Button
            color="error"
            startIcon={<DeleteIcon />}
            onClick={props.deleteHandler(index)}
          >
            Delete
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default ItemSale;