import SelectField from "../select.widget";
import { Shipping } from "../../helpers/data-load";

export const ShippingSelect = ({...props}) => {

  const shipping = Shipping;
    return (
      <SelectField
        data={shipping}
        {...props}
      />
    );
}