import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: 'app',
    initialState: {
      app: {
        openLoader: false
      }
    },
    reducers: {
      loaderStatus: (state) => {
        state.app.openLoader = !state.app.openLoader;
      },
    }
})

export const { loaderStatus } = appSlice.actions;

export default appSlice.reducer;
