import { serialize } from 'object-to-formdata';

import AppService from './app.service';

class UserService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('users');
  }
//   create(data) {
//     const formData = serialize(data);
//     return this.http.post(`${this.path}`, formData);
//   }
//   read(id) {
//     return this.http.get(`${this.path}/${id}`);
//   }
//   update(data, id) {
//     const formData = serialize({
//       ...data,
//       _method: 'PATCH',
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   uploadLogo(value, id) {
//     const formData = serialize({
//       logo: value,
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   getLogo(id) {
//     return this.http.get(`${this.path}/${id}/logo`);
//   }

list() {
  return this.http.get(`${this.path}/list`);
}
  all(pageSize, page) {
    return this.http.get(`${this.path}?render=paginate&size=${pageSize}&page=${page}`);
  }
//   filterStatus(status, pageSize, page) {
//     return this.http.get(
//       `${this.path}?filter[status]=${status}&render=paginate&size=${pageSize}&page=${page}`
//     );
//   }
//   search(query, pageSize = 20, page = 1) {
//     return this.http.get(
//       `${this.path}?render=paginate&size=${pageSize}&page=${page}&filter[name]=${query}`
//     );
//   }
//   listSearch(search) {
//     return this.http.get(`${this.path}?${search}`);
//   }
  me() {
    return this.http.get(`/users`);
  }
}

export default UserService;
