import { serialize } from 'object-to-formdata';

import AppService from './app.service';

class DocumentService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('document');
  }

  getDocument(saleId) {
    return this.http.get(`${this.path}/${saleId}`);
  }

  sendDocumentWhatsapp(saleId) {
    return this.http.get(`${this.path}/whatsapp?sale_id=${saleId}`);
  }
}

export default DocumentService;
