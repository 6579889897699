import has from 'lodash/has';
import {camelCase, mapKeys, mapValues, snakeCase} from 'lodash';

class AppHelper {
  static pct(percentage, value) {
    let temp = (percentage * value) / 100;
    return Math.round(temp);
  }

  static getErrorMessage(error) {
    const defaultMessage = 'Unknown error';
    let message = has(error, 'response.data.message')
      ? error.response.data.message
      : defaultMessage;
    if (message === defaultMessage) {
      message = has(error, 'response.data.msg')
        ? error.response.data.msg
        : defaultMessage;
    }
    if (message === defaultMessage) {
      message = has(error, 'message') ? error.message : defaultMessage;
    }
    return message;
  }

  static mergeModels(modelA, modelB) {
    return mapValues(modelA, function (value, key) {
      if (has(modelB, key)) {
        return modelB[key];
      }
      return value;
    });
  }

  static toSnakeCase(model) {
    return mapKeys(model, function (value, key) {
      return snakeCase(key);
    });
  }

  static toCamelCase(data) {
    return mapKeys(data, function (value, key) {
      return camelCase(key);
    });
  }

  static compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  static labelStatus(val) {
    const map = {
      1: 'Pendiente',
      2: 'Entregado',
      3: 'Anulado',
      4: 'Falta pagar',
    };

    return map[val];
  }

  static labeStore(val) {
    const map = {
      1: 'Online',
      2: 'Jesus Maria',
      3: 'Surco',
    };

    return map[val];
  }
}

export default AppHelper;
