import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Container } from '@mui/material';
import { SaleListResults } from '../components/sale/sale-list-results';
import { SalesListToolbar } from '../components/sale/sale-list-toolbar';
import SaleService from '../services/sale.service';
import DocumentService from '../services/document.service';
import LoaderWidget from '../core/widgets/loader/loader.widget';
import { snackbarShow } from '../app/features/snackbar/snackbarSlice';
import { isNumber } from 'lodash';
import dayjs from 'dayjs';

const Sales = () => {
  const saleService =  new SaleService();
  const documentService = new DocumentService();
  const dispatch =  useDispatch();
  const { user } = useSelector(state => state.user);
  const [sales, setSales] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState(0);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState({
    status: 0,
    store_id: '',
    range_date: ['',''],
    start_date: '',
    end_date: '',
    search: ''
  });
  const [loader, setLoader] = useState(false);

  const getSales = async (p, l, q) => {
    try {
      saleService.setAccessToken(user.accessToken);
      const response = await saleService.getSales(p, l, q);
      setData(response.data);
      setSales(response.data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const changePage = (page) => {
    setPage(page);
  }
  const changeLimit = (limit) => {
    setLimit(limit);
  }


  const selectHandler = (e) => {
    if (e.target.value !== '' && isNumber(e.target.value)) {
      setFilter(e.target.value);
    }
  }

  const handleInput = (val) => {
    setQuery({
      ...query,
      'start_date': dayjs(val[0]).format('YYYY-MM-DDTHH:mm:ssZ[Z]'),
      'end_date': dayjs(val[1]).format('YYYY-MM-DDTHH:mm:ssZ[Z]')
    })
  }

  const handleQuery = (event) => {
    const { name, value } = event.target;
    setQuery({...query, [name]: value});

  }

  const sendDocumentWhatsapp = async (id) => {

    setLoader(true);
    try {
      documentService.setAccessToken(user.accessToken);
      const response = await documentService.sendDocumentWhatsapp(id);
      const status = response.data.messages;
      if(status[0].message_status === 'accepted') {
        dispatch(snackbarShow({message: 'Documento enviado correctamente', type: 'success'}));
      } else {
        dispatch(snackbarShow({message: 'Hubo un error en el envio, inténtalo otra vez', type: 'error'}));
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
      dispatch(snackbarShow({message: 'Hubo un error en el envio, inténtalo otra vez', type: 'error'}));
    }
  }

  useEffect(()=>{
      getSales(page, limit, JSON.stringify(query));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, filter, query])

  return(
    <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false}>
        <SalesListToolbar
          onInput={handleInput}
          valSelect={filter}
          query={query}
          onSelect={selectHandler}
          onQuery={handleQuery}
        />
        <Box sx={{ mt: 3 }}>
          <SaleListResults
            sales={sales}
            changePage={changePage}
            changeLimit={changeLimit}
            count={data.records_total || 0}
            sendDocumentWhatsapp = {sendDocumentWhatsapp}
          />
        </Box>
      </Container>
      <LoaderWidget loader={loader} />
    </Box>
  </>
  )
};


export default Sales;
