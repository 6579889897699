import { Grid, Button, Paper } from "@mui/material";
import { styled } from '@mui/material/styles';
import SizeItem from "./size-item";

const ItemCenter = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: '#000',
  borderColor: 'transparent',
  boxShadow: 'none'
}));

const ItemLeft = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: '#000',
  borderColor: 'transparent',
  boxShadow: 'none'
}));

const  SizeItems = ({addHandler, removeHandler,  items, handleChangeItem}) => {
  return(
    <>
      <Grid container spacing={3}>
        <Grid item lg={10} md={10} xs={10}>
          <ItemLeft>VARIACIONES</ItemLeft>
        </Grid>
        <Grid item lg={2} md={2} xs={2}>
          <Button variant='contained' onClick={addHandler} color="success">+</Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item lg={5} md={5} xs={5}>
          <ItemCenter>TALLA</ItemCenter>
        </Grid>
        <Grid item lg={5} md={5} xs={5}>
          <ItemCenter>CANTIDAD</ItemCenter>
        </Grid>
        <Grid item lg={2} md={2} xs={2}>
          
        </Grid>
      </Grid>
      {
        items.map((item, i) => (
          <SizeItem
            key={i}
            index={i}
            quantity={item.quantity}
            size_id={item.size_id}
            removeHandler={removeHandler}
            handleChange={handleChangeItem}
          />
        ))
      }
    </>
  )
}

export default SizeItems;