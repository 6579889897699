import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete, TextField, CircularProgress } from "@mui/material"

import ProductService from "../../services/product.service";

export const AutocompleteWidget = (props) => {
  const { product } = props;
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [busca, setBusca] = useState('');
  const [_, setSelect] = useState(props.product)
  const { user, store } = useSelector(state => state.user);

  const productService =  new ProductService();

  const getProducts = async(b) => {
    try {
      setLoading(true);
      productService.setAccessToken(user.accessToken);
      const response = await productService.getSelect(b, 1, store);
      const prod = response.data.data;
      setProducts(prod.map(p => ({id: p.value, label: p.label, price: p.price})));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }
  

  useEffect(() => {
    getProducts(busca);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busca, store])

  return(
    <Autocomplete
      options={products}
      value={product || null}
      loading={loading}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
      onInputChange={(_, newValue) => {setBusca(newValue);}}
      onChange={(_, newValue) => {setSelect(newValue); props.selectPro(newValue);}}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Producto'
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" 
                  size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />)}
    />
  )
};

