import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { upperCase } from 'lodash';

import { AutocompleteComponent } from "../molecules/autocomplete";
import ProductService from "../../services/product.service";
import ColorService from "../../services/color.service";
import InventoryService from "../../services/inventory.service";
import SizeItems from "../size/size-items";

export const InventoryAddProductDialog = ({handleClickClose, openDialog, handleClickAlert, showMessage, loaderpage}) => {
  const { user } = useSelector((state) => state.user);
  const productService = new ProductService();
  const colorService = new ColorService();
  const inventoryService = new InventoryService();
  const [busca, setBusca] = useState("");
  const [buscaColor, setBuscaColor] = useState("");
  const [options, setOptions] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingColor, setLoadingColor] = useState(false);
  const [product, setProduct] = useState('');
  const [color, setColor] = useState('');
  const [items, setItems] = useState([
    {
      id: 'initial', 
      size_id: '',
      quantity: ''
    }
  ]);

  function _sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const getProducts = async (b) => {
    setLoading(true);
    await _sleep(1000);
    try {
      productService.setAccessToken(user.accessToken);
      const response = await productService.getProducts(b, 1);
      const prod = response.data;
      setOptions(
        prod.map((p) => ({ id: p.id, label: p.category_name + ' ' + upperCase(p.name) , price: p.price }))
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getColors = async (b) => {
    setLoadingColor(true);
    await _sleep(1000);
    try {
      colorService.setAccessToken(user.accessToken);
      const response = await colorService.getColors(1, 30, b);
      console.log(response.data.data)
      const aux = response.data.data;
      setColors(
        aux.map((p) => ({ id: p.id, label: p.name }))
      );
      setLoadingColor(false);
    } catch (error) {
      console.log(error);
      setLoadingColor(false);
    }
  };

  const handleInput = (val) => {
    setBusca(val);
  };

  const handleInputColor = (val) => {
    setBuscaColor(val);
  };

  const addHandler = () => {
    setItems([...items, { id: '', quantity: '', size_id: '' }])
  }

  const removeHandler = (index) => () => {
    setItems(
      items.filter((items, i) => {
        return index !== i;
      })
    )
  }

  const handleChangeItem = (index) => (event) => {
    let aux = items.map((item, i) => {
      if (index !== i) return item
      return {...item, [event.target.name]: event.target.value}
    })

    setItems(aux);
  }

  const selectItem = (val) => {
    setProduct(val);
  }

  const selectColor = (val) => {
    setColor(val);
  }

  const submit = () => {
    if (color && product) {
      (async () => {
        const inventory = {
          product_id: product.id,
          color_id: color.id,
          items: items
        }

        try {

          inventoryService.setAccessToken(user.accessToken);
          const response = await inventoryService.newInventory(inventory);
          loaderpage(response);
          showMessage('Productos guardados!', 'success')
          handleClickClose(false);
          handleClickAlert();
          
        } catch (error) {
          console.log(error);
          showMessage('Error en el sistema', 'error')
          handleClickClose(false);
          handleClickAlert();
        }
      })()
      
    }
    
  
  }


  useEffect(() => {
    getProducts(busca, 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busca]);

  useEffect(() => {
    getColors(buscaColor, 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buscaColor]);

  return (
    <Dialog
      open={openDialog}
      maxWidth="sm"
      fullWidth={true}
      scroll='paper'
    >
      <form>
        <DialogTitle>Ingrese Productos</DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} mt={0.5}>
              <Grid item lg={12} md={12} xs={12}>
                <AutocompleteComponent
                  data={options}
                  handleInput={handleInput}
                  selectItem={selectItem}
                  label={'Producto'}
                  loading={loading}
                  name='product'
                  value={product}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <AutocompleteComponent
                  data={colors}
                  handleInput={handleInputColor}s
                  selectItem={selectColor}
                  label={'Color'}
                  loading={loadingColor}
                  name='color'
                  value={color}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <SizeItems
                  items={items}
                  addHandler={addHandler}
                  removeHandler={removeHandler}
                  handleChangeItem={handleChangeItem}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} variant='outlined' >Cancelar</Button>
          <Button onClick={submit} variant='contained'>Guardar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
