import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Container } from "@mui/material";

import { ColorListResults } from "../components/color/color-list-results";
import { ColorListToolbar } from "../components/color/color-list-toolbar";
import ColorDialog from "../components/color/color-dialog";
import ColorService from "../services/color.service";
import { snackbarShow } from "../app/features/snackbar/snackbarSlice";

const initialValues = {
  name: "",
  code: "",
};

const Colors = () => {

  const colorService = new ColorService();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [colors, setColors] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(initialValues);
  
  const getColors = async (p, l, s, f) => {
    try {
      colorService.setAccessToken(user.accessToken);
      const response = await colorService.getColors(p, l, s);
      setColors(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page) => {
    setPage(page);
  };
  const changeLimit = (limit) => {
    setLimit(limit);
  };

  const handleInput = (event) => {
    setSearch(event.target.value);
  };

  const handleModal = () => {
    setOpen(true);
  };

  const handleModalEdit = (p) => {
    setColor(p);
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setColor(initialValues);
  };


  const saveColor = async (data) => {
    try {
      colorService.setAccessToken(user.accessToken);
      if (data.id) {
        await colorService.updateColor(data.id, data);
      } else {
        await colorService.newColor(data);
      }
      getColors(page, limit, search);
      handleModalClose();
      dispatch(snackbarShow({message: "Color guardado!", type: "success"}));
    } catch (error) {
      console.log(error);
      dispatch(snackbarShow({message: "Se produjo un error!", type: "error"}));
    }
  };

  useEffect(() => {
    getColors(page, limit, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, search]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <ColorListToolbar onInput={handleInput} openModal={handleModal} />
          <ColorDialog
            open={open}
            closeModal={handleModalClose}
            color={color}
            saveColor={saveColor}
          />
          <Box sx={{ mt: 3 }}>
            <ColorListResults
              colors={colors.data}
              changePage={changePage}
              changeLimit={changeLimit}
              count={colors.records_total || 0}
              open={open}
              closeModal={handleModalClose}
              openModalEdit={handleModalEdit}
              color={color}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Colors;
