import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Container } from "@mui/material";

import { StoreListToolbar } from "../components/store/store-list-toolbar";
import { StoreListResults } from "../components/store/store-list-results";
import ProductService from "../services/product.service";
import StoreDialog from "../components/store/store-dialog";
import StoreInventoryDialog from "../components/store/store-inventory-dialog";
import { snackbarShow } from "../app/features/snackbar/snackbarSlice";

const initialValues = {
  name: "",
  price: "",
  category_id: "",
  description: "",
};

const Stores = () => {

  const productService = new ProductService();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState(initialValues);
  const [openInventory, setOpenInventory] = useState(false);
  const [stock, setStock] = useState([]);
  
  const getProducts = async (p, l, s, f) => {
    try {
      productService.setAccessToken(user.accessToken);

      const response = await productService.getProducts(s);

      setData(response.data);
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      productService.setAccessToken(user.accessToken);
      const response = await productService.getCategories();
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page) => {
    setPage(page);
  };
  const changeLimit = (limit) => {
    setLimit(limit);
  };

  const handleInput = (event) => {
    setSearch(event.target.value);
  };

  const handleModal = () => {
    setOpen(true);
  };

  const handleModalEdit = (p) => {
    setProduct(p);
    setOpen(true);
  };

  const handleModaInventory = (p) => {
    getStock(p);
    setProduct(p);
    setOpenInventory(true);
  }

  const handleModalClose = () => {
    setOpen(false);
    setProduct(initialValues);
    setStock([]);
  };

  const handleModalCloseInventory = () => {
    setOpenInventory(false);
    setProduct(initialValues);
  };


  const saveProduct = async (data) => {
    try {
      productService.setAccessToken(user.accessToken);
      if (data.id) {
        await productService.updateProduct(data.id, data);
      } else {
        await productService.newProduct(data);
      }
      getProducts(page, limit, search);
      handleModalClose();
      dispatch(snackbarShow({message: "Producto guardado!", type: "success"}));
    } catch (error) {
      console.log(error);
      dispatch(snackbarShow({message: "Se produjo un error!", type: "error"}));
    }
  };

  const getStock = async (item) => {
    try {
      productService.setAccessToken(user.accessToken);
      const response = await productService.stock(item.id);
      setStock(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProducts(page, limit, search);
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, search]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <StoreListToolbar onInput={handleInput} openModal={handleModal} />
          <StoreDialog
            open={open}
            closeModal={handleModalClose}
            product={product}
            categories={categories}
            saveProduct={saveProduct}
          />
          <StoreInventoryDialog
            open={openInventory}
            closeModalInventory={handleModalCloseInventory}
            stock={stock}
            product={product}
          />
          <Box sx={{ mt: 3 }}>
            <StoreListResults
              products={products}
              changePage={changePage}
              changeLimit={changeLimit}
              count={data.records_total || 0}
              open={open}
              closeModal={handleModalClose}
              openModalEdit={handleModalEdit}
              closeModalInventory={handleModalCloseInventory}
              product={product}
              openInventory={openInventory}
              openModalInventory={handleModaInventory}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Stores;
