import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  TableContainer,
  Paper
} from '@mui/material';
import { upperCase } from 'lodash';

import InventoryEditDialog from './inventory-edit-dialog';


export const InventoryListResults = ({ products, openDialogEdit, changePage, changeLimit, editInventory, closeModalEdit, openModalEdit, count, ...rest }) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const[product, setProduct] = useState({
    quantity: 0,
    current: 0,
    stock: 0
  });


  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  const openDialogModal = (p) => {
    setProduct(p);
  }

  return (
    <>
      <Card {...rest}>
        <TableContainer component={Paper}>
          <Table sx={{ maxHeight: 440 }}>
            <TableHead>
              <TableRow>
                <TableCell >
                  Id
                </TableCell>
                <TableCell>
                  Nombre
                </TableCell>
                <TableCell>
                  Color
                </TableCell>
                <TableCell>
                  Talla
                </TableCell>
                <TableCell>
                  Cantidad
                </TableCell>
                <TableCell>
                  Stock
                </TableCell>
                <TableCell>
                  Precio
                </TableCell>
                <TableCell>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((product) => (
                <TableRow
                  hover
                  key={product.id}
                >
                  <TableCell padding="checkbox">
                    {product.id} 
                  </TableCell>
                  <TableCell>
                    {product.category_name} {' '} {upperCase(product.product_name)}
                  </TableCell>
                  <TableCell>
                    {product.color_name}
                  </TableCell>
                  <TableCell>
                    {product.size_name}
                  </TableCell>
                  <TableCell>
                    {product.quantity}
                  </TableCell>
                  <TableCell>
                    {product.stock}
                  </TableCell>
                  <TableCell>
                    S/ {`${product.price}.00`}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => {openModalEdit(); openDialogModal(product); }} variant='contained' color='success'>Editar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody> 
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Card>
      <InventoryEditDialog
        openModalEdit={openModalEdit}
        closeModalEdit={closeModalEdit}
        product={product}
        editInventory={editInventory}
        openDialogEdit={openDialogEdit}
      />           
    </>
  );
};

InventoryListResults.propTypes = {
  sales: PropTypes.array.isRequired
};
