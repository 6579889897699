export const Shipping = [
    {
      value: 1,
      key: 'Malicia'
    },
    {
      value: 2,
      key: 'Olva Courier'
    },
    {
      value: 3,
      key: 'Agencia'
    },
    {
      value: 4,
      key: 'Tienda'
    },
    {
      value: 5,
      key: 'Tienda Jesus maria'
    },
    {
      value: 6,
      key: 'Tienda Surco'
    },
    {
      value:7,
      key: 'Motorizado'
    },
    {
      value: 8,
      key: 'Almacen'
    },
  ]

  // const items = [
  //   {
  //     href: '/admin/dashboard',
  //     icon: (<ChartBarIcon fontSize="small" />),
  //     title: 'Dashboard'
  //   },
  //   {
  //     href: '/admin/products',
  //     icon: (<ShoppingBagIcon fontSize="small" />),
  //     title: 'Productos'
  //   },
  //   {
  //     href: '/admin/inventory',
  //     icon: (<FactCheckIcon fontSize="small" />),
  //     title: 'Inventario'
  //   },
  //   {
  //     href: '/admin/sales',
  //     icon: (<ShoppingCartIcon fontSize="small" />),
  //     title: 'Ventas'
  //   },
  //   {
  //     href: '/admin/customers',
  //     icon: (<UsersIcon fontSize="small" />),
  //     title: 'Clientes'
  //   },
  //   {
  //     href: '/admin/colors',
  //     icon: (<PaletteIcon fontSize="small" />),
  //     title: 'Colores'
  //   },
    
  // ];