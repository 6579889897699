import { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Container, Snackbar  } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { InventoryListToolbar } from '../components/inventory/inventory-list-toolbar';
import { InventoryListResults } from '../components/inventory/inventory-list-results';
import { InventoryAddProductDialog } from '../components/inventory/inventory-add-product-dialog';
import InventoryService from '../services/inventory.service';

const Inventory = () => {
  const inventoryService =  new InventoryService();
  const { user } = useSelector(state => state.user);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert, setAlert] = useState('');
  const [change, setChange] = useState('');

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const getProducts = async (p, l, s, f) => {
    try {
      inventoryService.setAccessToken(user.accessToken);
      const response = await inventoryService.getInventories(p, l, s);
      setData(response.data);
      setProducts(response.data.data);
    } catch (error) {
      console.log(error)
    }
  };

  const addProductModal = () => {
    setOpen(true);
  }

  const closeProductModal = () => {
    setOpen(false);
  }

  const changePage = (page) => {
    setPage(page);
  }
  const changeLimit = (limit) => {
    setLimit(limit);
  }


  const handleInput = (event) => {
    setSearch(event.target.value);
  }

  const handleClick = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const showMessage = (txt, action) => {
    setMessage(txt);
    setAlert(action)
  }

  const loaderpage = (val) => {
    setChange(val);
  }

  const openModalEdit = () => {
    setOpenEdit(true);
  }

  const closeModalEdit = () => {
    setOpenEdit(false);
  }

  const editInventory = async(data) => {
    try {
      inventoryService.setAccessToken(user.accessToken);
      await inventoryService.updateInventory(data, data.id);
      getProducts(page, limit, search);
      closeModalEdit();
      setOpenAlert(true);
      showMessage('Inventario actualizado', 'success')
    } catch (error) {
      console.log(error);
      closeModalEdit();
      setOpenAlert(true);
      showMessage('Error', 'error')
    }
  }
 

  useEffect(()=>{
    // (async function getRecords() {
        getProducts(page, limit, search);
    // })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, search, change])

  return(
    <>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false}>
        <InventoryListToolbar
          onInput={handleInput}
          handleClickOpen={addProductModal}
        />
        <InventoryAddProductDialog
          openDialog={open}
          handleClickClose={closeProductModal} 
          handleClickAlert={handleClick}
          showMessage={showMessage}
          loaderpage={loaderpage}
        />
        <Box sx={{ mt: 3 }}>
          <InventoryListResults
            products={products}
            changePage={changePage}
            changeLimit={changeLimit}
            count={data.records_total || 0}
            editInventory={editInventory}
            closeModalEdit={closeModalEdit}
            openModalEdit={openModalEdit}
            openDialogEdit={openEdit}
          />
        </Box>
      </Container>
    </Box>
    <Snackbar
      open={openAlert}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={alert} sx={{ width: '100%' }}>
          {message}
        </Alert>
    </Snackbar>
  </>
  )
};


export default Inventory;
