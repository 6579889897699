import { Navigate, Route, Routes } from 'react-router-dom';

import Login from '../../pages/Login';
import WebLayout from '../layouts/WebLayout';

export const WebRoute = () => (
  <Routes>
    <Route element={<WebLayout />}>
      <Route path='*' element={<Navigate to='/login' />} />
      <Route exact path='/login' element={<Login />} />
    </Route>
  </Routes>
);