
import {Grid} from "@mui/material";
import InputField from "../../../core/widgets/input.widget";
import DatePickerField from "../../../core/widgets/datepicker.widget";
import { ShippingSelect } from "../../../core/widgets/custom/shipping.select";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/es-mx';


const DetailSaleForm = (props) => {
  
  return(
    <>
      <Grid container
        spacing={2}
      >
        <Grid
            item
            md={6}
            xs={12}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='es-mx'
          >
            <DatePickerField
              name='sale_at'
              label='Fecha de compra'
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='es-mx'
          >
            <DatePickerField
              name='send_at'
              label='Fecha de envio'
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputField
            name='shipping'
            label='Costo de envio'
            fullWidth
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <ShippingSelect
            fullWidth
            label="Forma de envio"
            name="shipping_type"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputField
            fullWidth
            label="Nro de Tracking"
            name="nro_tracking"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </>
  )

}

export default DetailSaleForm;