import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Selector as SelectorIcon } from '../icons/selector';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import StoreIcon from '@mui/icons-material/Store';
import { Users as UsersIcon } from '../icons/users';
import PaletteIcon from '@mui/icons-material/Palette';
import { Logo } from './logo';
import { NavItem } from './nav-item';

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });

  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div>
          <Box sx={{ p: 3 }}>
          <Link to='/admin/dashboard'>
                <Logo
                  sx={{
                    height: 42,
                    width: 42
                  }}
                />
              
            </Link>
          </Box>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'space-between',
                px: 3,
                py: '11px',
                borderRadius: 1
              }}
            >
              <div>
                <Typography
                  color="inherit"
                  variant="subtitle1"
                >
                  MALICIA MODA
                </Typography>
                
              </div>
              <SelectorIcon
                sx={{
                  color: 'neutral.500',
                  width: 14,
                  height: 14
                }}
              />
            </Box>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
           < NavItem
            href='/admin/dashboard'
            title='Dashboard'
            icon={(<ChartBarIcon fontSize="small" />)}
          />
           < NavItem
            href='/admin/products'
            title='Productos'
            icon={(<ShoppingBagIcon fontSize="small" />)}
          />
           < NavItem
            href='/admin/inventory'
            title='Inventario'
            icon={(<FactCheckIcon fontSize="small" />)}
          />
          < NavItem
            href='/admin/sales'
            title='Ventas'
            icon={(<ShoppingCartIcon fontSize="small" />)}
          />
          < NavItem
            href='/admin/customers'
            title='Clientes'
            icon={(<UsersIcon fontSize="small" />)}
          />
          < NavItem
            href='/admin/colors'
            title='Colores'
            icon={(<PaletteIcon fontSize="small" />)}
          />
          < NavItem
            href='/admin/stores'
            title='Tiendas'
            icon={(<StoreIcon fontSize="small" />)}
          />
        </Box>
        <Divider sx={{ borderColor: '#2D3748' }} />
        <Box
          sx={{
            px: 2,
            py: 3
          }}
        >
          <Typography
            color="neutral.100"
            variant="subtitle2"
          >
            {/* Need more features? */}
          </Typography>
          <Typography
            color="neutral.500"
            variant="body2"
          >
            {/* Check out our Pro solution template. */}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              mt: 2,
              mx: 'auto',
              width: '160px',
              '& img': {
                width: '100%'
              }
            }}
          >
            {/* <img
              alt="Go to pro"
              src="/static/images/sidebar_pro.png"
            /> */}
          </Box>
          {/* <NextLink
            href="https://material-kit-pro-react.devias.io/"
            passHref
          >
            <Button
              color="secondary"
              component="a"
              endIcon={(<OpenInNewIcon />)}
              fullWidth
              sx={{ mt: 2 }}
              variant="contained"
            >
              Pro Live Preview
            </Button>
          </NextLink> */}
        </Box> 
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
