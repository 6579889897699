import AppService from './app.service';

class ColorService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('colors');
  }

  getColors(page, limit, search) {
    return this.http.get(`${this.path}?page=${page}&per_page=${limit}&search_by=${search}`);
  }

  getProductSelect() {
    return this.http.get(`inventory/select`);
  }

  newColor(data) {
    return this.http.post("/color/new", data);
  }

  updateColor(id, data) {
    return this.http.put("/color/" + id, data);
  }
}

export default ColorService;
