import * as React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';


// import Books from '../../pages/Books';
import Courses from '../../pages/Courses';
import Dashboard from '../../pages/Dashboard';
import Products from '../../pages/Products';
import Inventory from '../../pages/Inventory';
import Customers from '../../pages/Customers';
import Sales from '../../pages/Sales';
import NewSalePage from '../../pages/sales/new';
import Colors from '../../pages/Colors';
import Stores from '../../pages/Stores';
// import Print from '../../pages/sales/Print';
import AdminLayout from '../layouts/AdminLayout';
import SaleEdit from '../../pages/sales/edit';
// import ViewDocument from '../../pages/documents/view';
const ViewDocument = React.lazy(() => import('../../pages/documents/view'))

export const AdminRoute = () => (
  <Routes>
    <Route element={<AdminLayout />}>
      <Route path='*' element={<Navigate to='/admin/dashboard' />} />
      <Route exact path='/sales' element={<Sales />} />
      <Route exact path='/sales/new' element={<NewSalePage />} />
      <Route exact path='/sales/:id/edit' element={<SaleEdit />} />
      <Route exact path='/products' element={<Products />} />
      <Route exact path='/inventory' element={<Inventory />} />
      <Route exact path='/customers' element={<Customers />} />
      <Route exact path='/colors' element={<Colors />} />
      <Route exact path='/stores' element={<Stores />} />
      {/* <Route exact path='/sales/view' element={<Print />} /> */}
      <Route
          path='/document/:saleId'
          element={
            <React.Suspense fallback={<>...</>}>
              <ViewDocument />
            </React.Suspense>
          }
        />
      {/* <Route exact path='/document/:saleId' loader={} element={<ViewDocument />} /> */}
      <Route exact path='/dashboard' element={<Dashboard />} />
      {/* <Route exact path='/customer/:id' element={<AccountPage />} /> */}
      <Route exact path='courses' element={<Courses />} />
    </Route>
  </Routes>
);