import { TextField } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useField, useFormikContext } from "formik";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DemoContainer components={["DatePicker"]}>
      <DatePicker
        {...props}
        value={field.value ? dayjs(new Date(field.value)) : null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
        renderInput={(params) => (
          <TextField {...params} fullWidth sx={{ marginBottom: "20px" }} className="Daniel" />
        )}
      />
    </DemoContainer>
  );
};

export default DatePickerField;
