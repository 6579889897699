import { useState } from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

function SelectField(props) {
  const { label, data, ...rest } = props;
  const [field, meta, setFieldValue] = useField(props);
  const [medValue, setMedValue] = useState(null);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest}
      error={isError}
    >
      <InputLabel>{label}</InputLabel>
      <Select 
        {...field}
        value={selectedValue ? selectedValue : ''}
        label={label}
      >
        {data.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
          >
            {item.key}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectField.defaultProps = {
  data: [],
  label: ''
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
};

export default SelectField;
