import { Formik, Form } from 'formik';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as yup from 'yup';

import { CustomerForm } from "./forms/customer-form";

const validationSchema = yup.object({
  first_name: yup
    .string('Ingrese el nombre')
    .required('El nombre es obligatorio'),
  last_name: yup
    .string('Ingrese el Apellido')
    .required('El apellido es obligatorio'),
  full_name: yup
    .string('Ingrese el nombre y apellido')
    .required('El nombre y apellido es obligatorio'),
  address: yup
    .string('Ingrese una dirección')
    .required('La dirección es obligatorio'),
  dni: yup
  .string('Debe ingresar un número')
  .matches(/^[0-9]+$/, "Ingresa solo numeros")
  .min(8, "Debes ingresar 8 digitos")
  .max(8, "Debes ingresar 8 digitos")
  .required('El DNI es obligatorio'),
  phone: yup
  .string('Debe ingresar un número')
  .matches(/^[0-9]+$/, "Ingresa solo numeros")
  .min(9, "Debes ingresar 9 digitos")
  .max(9, "Debes ingresar 9 digitos")
  .required('El teléfono es obligatorio'),
});

export const CustomerDialog = ({open, customer, categories, closeModal, saveCustomer, searchDni}) => {

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} scroll="paper">
      <Formik
        initialValues={customer}
        onSubmit={
          (values) => {
            saveCustomer(values);
          }
        }
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(props) => (
          <Form >
            <DialogTitle>
              Nuevo Cliente
            </DialogTitle>
            <DialogContent>
              <CustomerForm {...props} searchDni={searchDni}  />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal} variant="outlined">
                Cancelar
              </Button>
              <Button type="submit" variant="contained">
                Guardar
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

