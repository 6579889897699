import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState: {
        snackbarOpen : false,
        snackbarMessage: '',
        snackbarType: 'success'
    },
    reducers: {
      snackbarShow: (state, action) => {
        state.snackbarOpen = true;
        state.snackbarType = action.payload.type;
        state.snackbarMessage = action.payload.message;
      },
      snackbarClear: (state, action) =>  {
        state.snackbarOpen = false;
        state.snackbarMessage = '';
        state.snackbarType = 'success';
      },
    }
})

export const { snackbarShow, snackbarClear } = snackbarSlice.actions;

export default snackbarSlice.reducer;