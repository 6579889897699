
import { Typography, Grid, Divider} from "@mui/material";
import InputField from "../../../core/widgets/input.widget";
import SelectField from "../../../core/widgets/select.widget";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerField from "../../../core/widgets/datepicker.widget";
import 'dayjs/locale/es-mx';



const docs = [
  {
    value: 3,
    key: 'Boleta',
  },
  {
    value: 1,
    key: 'Factura',
  }
];


const SaleForm = (props) => {
  const { values, handleChange, typeForm} = props;

  const handleChangeDni = (event) => {
    if(event.target.value.length > 7) {
      props.passData(event.target.value);
    }
    handleChange(event);
    
  };

  const handleChangeRuc = (event) => {
    if(event.target.value.length > 10) {
      props.passDataRuc(event.target.value);
    }
    handleChange(event);
  };

  return(
    <>
      <Grid container
        spacing={2}
      >
        {
          typeForm === 2 && 
            <Grid
            item
            md={4}
            xs={12}
        >
          <SelectField
            name='document'
            data={docs}
            fullWidth
          />
        </Grid>
        }
        <Grid
          item
          md={4}
          xs={12}
        >
          <InputField
            name='dni'
            value={values.dni}
            onChange={handleChangeDni}
            label='DNI'
            fullWidth
            inputProps={{
              maxLength: 8,
            }}
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <InputField
            fullWidth
            label="Celular"
            name="phone"
            value={values.phone}
            variant="outlined"
            inputProps={{
              maxLength: 9,
            }}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputField
            fullWidth
            label="Nombre y Apellidos"
            name="full_name"
            value={values.full_name}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <InputField
            fullWidth
            label="Corre electrónico"
            name="email"
            value={values.email}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
        >
          <InputField
            fullWidth
            label="Dirección"
            name="address"
            value={values.address}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='es-mx'
          >
            <DatePickerField
              name='birthday'
              label='Cumpleaños'
              value={values.birthday}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {
        values.document === 1 &&
        <>
          <Divider />
          <Grid 
            container
            spacing={1}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Typography
                sx={{ mb: 3, mt: 3 }}
                variant="h6"
              >
                Datos de la Empresa
              </Typography>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <InputField
                fullWidth
                label="RUC"
                name="ruc"
                value={values.ruc}
                onChange={handleChangeRuc}
                variant="outlined"
                inputProps={{
                  maxLength: 11,
                }}
              />
            </Grid>
            <Grid
              item
              md={8}
              xs={12}
            >
              <InputField
                fullWidth
                label="Razon Social"
                name="bussines_name"
                value={values.bussines_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <InputField
                fullWidth
                label="Dirección"
                name="bussines_address"
                value={values.bussines_address}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </>
      }
    </>
  )

}

export default SaleForm;