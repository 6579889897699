import {
  Box,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

const ProductForm = (props) => {
  const { values, errors, handleChange, handleBlur, touched, categories } =
    props;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mt={0.5}>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="name"
            value={values.name}
            placeholder="Nombre"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="category_id_label">Categoria</InputLabel>
            <Select
              labelId="category_id_label"
              id="category_id"
              name="category_id"
              value={values.category_id}
              label="Categoria"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.category_id && Boolean(errors.category_id)}
              helperText={touched.category_id && errors.category_id}
            >
              {categories.map((category, i) => (
                <MenuItem key={i} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="price"
            value={values.price}
            placeholder="Precio"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.price && Boolean(errors.price)}
            helperText={touched.price && errors.price}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="description"
            value={values.description}
            placeholder="Descripcion"
            fullWidth
            onChange={handleChange}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductForm;
