import { useState } from 'react';
import PropTypes from 'prop-types';
import { SeverityPill } from '../severity-pill';
import { format, parseISO } from 'date-fns';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Paper,
  Stack,
  Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


export const SaleListResults = ({ sales, changePage, sendDocumentWhatsapp, changeLimit, count, ...rest }) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    changeLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  const options = (opt) => {
    switch (opt) {
      case 2: return 'Entregado';
      case 3: return 'Anulado';
      case 4: return 'Con Deuda';
      default: return 'Pendiente';
    };
  }

  return (
    <>
      <Card {...rest}>
        <TableContainer component={Paper}>
          <Table sx={{ maxHeight: 440 }}>
            <TableHead>
              <TableRow>
                <TableCell >
                  Id
                </TableCell>
                <TableCell>
                  Fecha de Compra
                </TableCell>
                <TableCell>
                  DNI
                </TableCell>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Monto
                </TableCell>
                <TableCell>
                  Enviar por
                </TableCell>
                <TableCell>
                  Fecha de envio
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Tienda
                </TableCell>
                <TableCell>
                  Acciones
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales?.map((sale) => (
                <TableRow
                  hover
                  key={sale.id}
                >
                  <TableCell padding="checkbox">
                    {sale.id}
                  </TableCell>
                  <TableCell>
                    {format(parseISO(sale.sale_at), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    {sale.dni}
                  </TableCell>
                  <TableCell>
                    {sale.full_name}
                  </TableCell>
                  <TableCell>
                    S/ {`${sale.price}.00`}
                  </TableCell>
                  <TableCell>
                    {sale.shipping_type_name}
                  </TableCell>
                  <TableCell>
                    {format(parseISO(sale.send_at), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell>
                    <SeverityPill
                      color={(sale.status === 2 && 'success')
                      || (sale.status === 3 && 'error')
                      || (sale.status === 4 && 'info')
                      || 'warning'}
                    >
                      {options(sale.status)}
                    </SeverityPill>
                  </TableCell>
                  <TableCell>
                    {sale.store_name}
                  </TableCell>
                  <TableCell>
                    <Stack spacing={2} direction="row">
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{paddingRight: "0px", paddingLeft: "0px", minWidth: "40px"}}
                      >
                        <VisibilityIcon />
                      </Button>
                      <Link to={`/admin/sales/${sale.id}/edit`} key={`edit-${sale.id}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{paddingRight: "0px", paddingLeft: "0px", minWidth: "40px"}}
                        >
                          <EditIcon />
                        </Button>
                      </Link>
                      <Link to={`/admin/document/${sale.id}`} key={`print-${sale.id}`}>
                        <Button
                          variant="contained"
                          color='info'
                          sx={{paddingRight: "0px", paddingLeft: "0px", minWidth: "40px"}}
                        >
                          <PrintIcon />
                        </Button>
                      </Link>
                      <Button
                        variant="contained"
                        color='success'
                        key={`btn-${sale.id}`}
                        onClick={() => sendDocumentWhatsapp(sale.id)}
                        sx={{paddingRight: "0px", paddingLeft: "0px", minWidth: "40px"}}
                      >
                        <WhatsAppIcon />
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 50]}
        />
      </Card>
    </>
  );
};

SaleListResults.propTypes = {
  sales: PropTypes.array.isRequired
};
