import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import AppHelper from "../../core/helpers/AppHelper";
import { upperCase } from "lodash";

const ItemCenter = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: "#000",
  borderColor: "transparent",
  boxShadow: "none",
}));

const ProductInventoryDialog = ({
  open,
  closeModalInventory,
  stock,
  product,
}) => {
  const [sizes, setSizes] = useState([]);

  const formatStock = () => {
    // let r = props.stock;
    let temp = {};
    stock.forEach((e) => {
      const color = e.color.name;
      const t = {
        size: e.size.name,
        stock: e.stock,
        quantity: e.quantity,
      };
      if (!temp.hasOwnProperty(color)) {
        temp[color] = [t];
      } else {
        temp[color].push(t);
      }
    });

    // Ordering XS, S, M, L, XL
    for (const key in temp) {
      temp[key].sort(AppHelper.compareValues("size", "desc"));
    }

    // Convert to Array for FlatList
    let tempArr = [];
    for (const key in temp) {
      const t = { color: key, sizes: temp[key] };
      tempArr.push(t);
    }
    console.log(tempArr);
    setSizes(tempArr);
  };

  useEffect(() => {
    if (stock?.length > 0) {
      formatStock();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true} scroll="paper">
      <DialogTitle>
        {product.category_name} {upperCase(product.name)}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {sizes?.map((item, i) => {
            return (
              <Grid container spacing={1} mt={0.5} pl={2} key={`color-${i}`}>
                <Grid item lg={12} md={12} xs={12}>
                  <ItemCenter sx={{ fontWeight: "bold" }}>
                    {item.color}
                  </ItemCenter>
                </Grid>
                <Grid container lg={12} md={12} xs={12}>
                  {item.sizes.map((it, y) => {
                    return (
                      <Grid
                        item
                        xs={12 / item.sizes.length}
                        sx={{
                          borderColor: "#000",
                          borderWidth: 1,
                          borderStyle: "solid",
                        }}
                        key={`size-${i}`}
                      >
                        <ItemCenter>
                          {it.size} - {it.quantity} ({it.stock})
                        </ItemCenter>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModalInventory} variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductInventoryDialog;
