import { da } from 'date-fns/locale';
import { serialize } from 'object-to-formdata';

import AppService from './app.service';

class SaleService extends AppService {
  constructor() {
    super();
    this.path = this.getPath('sale');
  }

  getProducts() {
    return this.http.get(`${this.path}`);
  }

  getProductSelect() {
    return this.http.get(`inventory/select`);
  }

  // getSales(page, limit, search, filter) {
  //   return this.http.get(`${this.path}/list?page=${page}&per_page=${limit}&search_by=${search}&filter_by=${filter}`);
  // }

  getSales(page, limit, query) {
    return this.http.get(`${this.path}/list?page=${page}&per_page=${limit}&query=${query}`);
  }

  create(data) {
    const formData = serialize(data);
    return this.http.post(`${this.path}/document`, formData);
  }

  update(data, id) {
    // const formData = serialize(data);
    return this.http.patch(`${this.path}/${id}`, data);
  }
  
  readSale(saleId) {
    return this.http.get(`${this.path}/edit/${saleId}`);
  }
//   read(id) {
//     return this.http.get(`${this.path}/${id}`);
//   }
//   update(data, id) {
//     const formData = serialize({
//       ...data,
//       _method: 'PATCH',
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   uploadLogo(value, id) {
//     const formData = serialize({
//       logo: value,
//     });
//     return this.http.post(`${this.path}/${id}`, formData);
//   }
//   getLogo(id) {
//     return this.http.get(`${this.path}/${id}/logo`);
//   }
  list(pageSize, page) {
    return this.http.get(`${this.path}?render=paginate&size=${pageSize}&page=${page}`);
  }
//   filterStatus(status, pageSize, page) {
//     return this.http.get(
//       `${this.path}?filter[status]=${status}&render=paginate&size=${pageSize}&page=${page}`
//     );
//   }
//   search(query, pageSize = 20, page = 1) {
//     return this.http.get(
//       `${this.path}?render=paginate&size=${pageSize}&page=${page}&filter[name]=${query}`
//     );
//   }
//   listSearch(search) {
//     return this.http.get(`${this.path}?${search}`);
//   }
}

export default SaleService;
