import {
  Box,
  Grid,
  TextField
} from "@mui/material";

export const CustomerForm = (props) => {
  const { values, errors, handleChange, handleBlur, touched, searchDni } =
    props;

    const handleChangeDni = (event) => {
      if(event.target.value.length > 7) {
        searchDni(event.target.value);
      }
      handleChange(event);
    }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mt={0.5}>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            name="dni"
            value={values.dni}
            placeholder="DNI"
            fullWidth
            onChange={handleChangeDni}
            onBlur={handleBlur}
            error={touched.dni && Boolean(errors.dni)}
            helperText={touched.dni && errors.dni}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            name="phone"
            value={values.phone}
            placeholder="Teléfono"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            name="first_name"
            value={values.first_name}
            placeholder="Nombres"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.first_name && Boolean(errors.first_name)}
            helperText={touched.first_name && errors.first_name}
          />
        </Grid>
        <Grid item lg={6} md={6} xs={12}>
          <TextField
            name="last_name"
            value={values.last_name}
            placeholder="Apellidos"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.last_name && Boolean(errors.last_name)}
            helperText={touched.last_name && errors.last_name}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="full_name"
            value={values.full_name}
            placeholder="Nombres y apellidos"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.full_name && Boolean(errors.full_name)}
            helperText={touched.full_name && errors.full_name}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="email"
            value={values.email}
            placeholder="Correo"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="address"
            value={values.address}
            placeholder="Dirección"
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.address && Boolean(errors.address)}
            helperText={touched.address && errors.address}
          />
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <TextField
            name="reference"
            value={values.reference || ''}
            placeholder="Referencia"
            fullWidth
            onChange={handleChange}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
